import { fetchAPI, FetchOptions } from "."
import { BrandSettings } from "../types/user-settings"

//  api.GET("/user/settings/:uid", s.getBrandSettings)
// 	api.GET("/user/settings/:uid/:storeID", s.getBrandSettings)
// 	marketing.PUT("/user/settings/:uid", s.putBrandSettings)
// 	marketing.PUT("/user/settings/:uid/:storeID", s.putBrandSettings)
// 	marketing.DELETE("/user/settings/:uid", s.deleteBrandSettings)
// 	marketing.DELETE("/user/settings/:uid/:storeID", s.deleteBrandSettings)


export function getUserSettings(storeID?: string, options?: FetchOptions): Promise<Partial<BrandSettings>> {
    if (storeID) return fetchAPI(`/user/settings/:uid/${storeID}`, { method: 'GET', ...options })
    return fetchAPI('/user/settings/:uid', { method: 'GET', ...options })
}

export function updateUserSettings(settings: Partial<BrandSettings>, storeID?: string, options?: FetchOptions): Promise<Partial<BrandSettings>> {
    if (storeID) return fetchAPI(`/user/settings/:uid/${storeID}`, { method: 'PUT', payload: settings, ...options })
    return fetchAPI('/user/settings/:uid', { method: 'PUT', payload: settings, ...options })
}

export function deleteUserSettings(storeID?: string, options?: FetchOptions): Promise<void> {
    if (storeID) return fetchAPI(`/user/settings/:uid/${storeID}`, { method: 'DELETE', ...options })
    return fetchAPI('/user/settings/:uid', { method: 'DELETE', ...options })
}


